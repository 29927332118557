import { api } from "@/instances/http";

class LocalWalletService {
  findMany(params = {}) {
    return api.post("/local/wallet/findMany", { ...params }).then(response => {
      return response.data;
    });
  }
  update(params = {}) {
    return api.post("/local/wallet/update", { ...params }).then(response => {
      return response.data;
    });
  }
  findUnique(params = {}) {
    return api.post("/local/wallet/update", { ...params }).then(response => {
      return response.data;
    });
  }
  findFirst(params = {}) {
    return api.post("/local/wallet/findFirst", { ...params }).then(response => {
      return response.data;
    });
  }
  withdraw(params = {}) {
    return api.post("/payments/withdraw", { ...params }).then(response => {
      return response.data;
    });
  }
  withdrawMoneyByAdmin(params = {}) {
    return api
      .post("/local/wallet/withdrawMoneyByAdmin", { ...params })
      .then(response => {
        return response.data;
      });
  }
  getHistory(params = {}) {
    return api
      .post("/local/wallet/getHistory", { ...params })
      .then(response => {
        return response.data;
      });
  }
  checkTransaction(params = {}) {
    return api
      .post("/local/wallet/checkTransaction", { ...params })
      .then(response => {
        return response.data;
      });
  }
}

export default new LocalWalletService();
