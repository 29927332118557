<template>
  <v-container fluid>
    <v-row class="mb-2">
      <v-col class="text-center text-h4 col-12">Локальные кошелкьки</v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Проверка транзакции в киви</v-card-title>
          <v-card-text>
            <v-text-field
              type="number"
              v-model="checkTransactionInput"
              placeholder="Номер транзакции в киви"
            ></v-text-field>
            <v-btn @click="checkTransaction">Проверить</v-btn>
            <v-textarea v-model="this.checkTransactionAnswer"></v-textarea>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="checkRoles(['general', 'majorSupport'])">
      <v-col>
        <!--        <v-btn @click="addWallet" class="primary">Добавить</v-btn>-->
        <v-btn @click="openSendMoneyDialog()" class="primary ml-1"
          >Отправить деньги</v-btn
        >
        <!--        <v-btn @click="deleteWallet" class="primary ml-1">Удалить</v-btn>-->
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Локальные кошельки
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            show-select
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="wallets"
            :loading="loading"
            loading-text="Загрузка... Пожалуйста подождите"
            :items-per-page="25"
            show-expand
            single-expand
            :expanded.sync="expanded"
          >
            <template v-slot:item.balance="{ item }">
              {{
                (item.balance / 100).toFixed(2)
                  | number("0,0", { thousandsSeparator: " " })
              }}
              ₽
            </template>
            <template v-slot:item.isEnabled="{ item }">
              <v-simple-checkbox color="green" :value="item.isEnabled" />
            </template>
            <template v-slot:item.isBlocked="{ item }">
              <v-simple-checkbox color="red" :value="item.isBlocked" />
            </template>
            <template v-slot:item.haveCommunalPayments="{ item }">
              <v-simple-checkbox
                color="green"
                :value="item.haveCommunalPayments"
              />
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:item.updatedAt="{ item }">
              {{ item.updatedAt | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-overlay :value="!item.history">
                  <v-progress-circular
                    indeterminate
                    size="64"
                  ></v-progress-circular>
                </v-overlay>
                <v-simple-table v-if="item.history" dark>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Время</th>
                        <th class="text-left">Статус</th>
                        <th class="text-left">Направление</th>
                        <th class="text-left">Сумма</th>
                        <th class="text-left">Аккаунт</th>
                        <th class="text-left">Id Транзакции в киви</th>
                        <th class="text-left">Id Транзакции нашей системе</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in item.history" :key="item.txnId">
                        <td>{{ item.date | moment("YYYY-MM-DD HH:mm:ss") }}</td>
                        <td>
                          <v-chip
                            :color="item.status === 'ERROR' ? 'red' : 'green'"
                            >{{ item.statusText }}</v-chip
                          >
                        </td>
                        <td>
                          <v-chip :color="item.type === 'IN' ? 'green' : 'red'"
                            >{{ item.type === "OUT" ? "Вывод" : "Депозит" }}
                          </v-chip>
                        </td>
                        <td>
                          {{
                            item.total.amount
                              | number("0,0", { thousandsSeparator: " " })
                          }}₽
                        </td>
                        <td>{{ item.account }}</td>
                        <td>{{ item.txnId }}</td>
                        <td>
                          <v-chip :color="item.internalLog ? 'green' : 'red'"
                            >{{
                              item.internalLog
                                ? item.internalLog.id
                                : "нет в системе"
                            }}
                          </v-chip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LocalWalletService from "@/services/local.wallet.service";
export default {
  name: "index",
  data: () => ({
    checkTransactionInput: "",
    checkTransactionAnswer: "",
    selected: [],
    expanded: [],
    headers: [
      { text: "id", value: "id" },
      { text: "Номер", value: "number" },
      { text: "token", value: "token" },
      { text: "Баланс", value: "balance" },
      { text: "Статус", value: "status" },
      { text: "blocked", value: "isBlocked" },
      { text: "enabled", value: "isEnabled" },
      { text: "CPayment", value: "haveCommunalPayments" },
      { text: "Создан", value: "createdAt" },
      { text: "Обновлен", value: "updatedAt" },
      { text: "Тип", value: "system.name" }
    ],
    search: "",
    wallets: [],
    loading: false
  }),
  watch: {
    async expanded(val) {
      if (val.length) {
        const history = await LocalWalletService.getHistory({
          walletId: val[0].id
        });
        const idx = this.wallets.findIndex(item => item.id === val[0].id);
        this.wallets[idx].history = history;
      }
    }
  },
  methods: {
    checkTransaction() {
      LocalWalletService.checkTransaction({
        id: this.checkTransactionInput
      }).then(data => {
        if (data.wallet) {
          const meta = JSON.parse(data.wallet.meta);
          this.checkTransactionAnswer = `Наш Кошелек ${meta.personId} Аккаунт: ${meta.account} Направление: ${meta.type} Статус: ${meta.status} Время: ${meta.date}  Сумма: ${meta.total.amount}`;
        } else {
          this.checkTransactionAnswer = "Ничего не нашел ><";
        }
      });
    },
    openSendMoneyDialog() {
      if (this.selected.length) {
        this.$store.dispatch("dialogs/dialog", {
          sendMoney: {
            show: true,
            data: {
              selected: this.selected
            }
          }
        });
      } else {
        this.$noty.error("Сначало выберите кошелек");
      }
    },
    loadWallets() {
      this.loading = true;
      LocalWalletService.findMany({
        include: {
          system: true
        }
      }).then(data => {
        this.wallets = data.map(item => {
          return {
            history: null,
            ...item
          };
        });
        this.loading = false;
      });
    },
    addWallet() {},
    sendMoney() {
      this.$store.dispatch("dialo");
    },
    deleteWallet() {}
  },
  created() {
    this.loadWallets();
  }
};
</script>

<style scoped></style>
